
.view-toggles {
  width: 100%;
}

.sentence-list-header {
  padding: 10px 10px 0;
}


.sentence-list-header-adjacent {
  display: none;
}

@media (min-width: 800px) {
  .sentence-list-header-stacked {
    display: none;
  }

  .sentence-list-header-adjacent {
    display: block;
  }

  .discussion-container {
    position: relative;
  }

  .sentence-list {
    position: absolute;
    top: 0;
    bottom: 0;
    height: 100vh;
    overflow-y: auto;
    width: 50%;
    padding-top: 20px;
  }
}

.discussion-editor {
  position: relative;
}

.discussion-editor-draft {
  background-color: lightyellow;
}

.discussion-actions {
  font-style: italic;
  background-color: white;
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  padding: 0 5px;
}

.sentence-line-cell {
  margin-bottom: 7px;
}

.sentence-meta {
  position: relative;
  user-select: none;
  padding-top: 2px;
  padding-right: 5px;
  font-size: 10pt;
  height: 100%;
  width: 100%;
}

.sentence-icon {
  font-size: 10pt;
}

.sentence-index {
  position: relative;
  font-family: Comic Sans;
  user-select: none;
  font-weight: bold;
  height: 20px;
  width: 20px;
  padding-right: 5px;
}

.sentence-in-argument {
  position: relative;
  user-select: none;
  padding-top: 4px;
  padding-left: 5px;
  font-size: 10pt;
  height: 100%;
  width: 100%;
}

.sentence-line-cell-in-modal {
  z-index: 4;
}

.indicator {
  border-radius: 50%;
  width: 10px;
  height: 10px;
  padding-right: 5px;
  display: inline-block;
  background-color: crimson;
}

.synced {
  background-color: #0f9960;
}

.sentence-modal-wrapper {
  position: relative;
}

.sentence-modal {
  width: calc(100% + 30px);
  background-color: white;
  top: -50px;
  z-index: 3;
  position: absolute;
  border: 1px solid black;
  padding: 10px;
  border-radius: 30px;
}

.sentence-modal-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.2);
  z-index: 2;
}

.sentence-hidden {
  opacity: 0.3;
}

/* forgive me. it's draftjs's fault */
.public-DraftEditorPlaceholder-inner {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
